<template>
  <div class="indexContent">
    <asideBar />
    <div class="sidebar_right mini-sidebar">
      <div class="main-content flex-fill">
        <div
          class="container customize-width px-md-5 mt-4"
          :style="{ 'margin-left': $store.state.isMobile ? '0px' : $store.state.collapse ? '200px' : '60px' }"
        >
          <div class="content">
            <div class="content-wrap">
              <div class="content-layout">
                <main>
                  <nav class="text-sm text-muted mb-4">
                    <i class="iconfont icon-home mr-2"></i>
                    <a class="crumbs" @click="clickJump('/')">首页</a>
                    <i class="px-2">/</i>
                    <a @click="clickJump('/class')">所有类别</a>
                    <i class="px-2">/</i>
                    <span>{{ detail.name }}</span>
                  </nav>
                  <div class="block-header my-4">
                    <h4 class="block-title"><i class="io io-VIP"></i>{{ detail.name }}</h4>
                    <p class="block-desc">介绍：{{ detail.desc }}</p>
                    <div class="block-btn-group mt-3">
                      <a class="btn" data-toggle="dropdown" v-clipboard:copy="'https://' + detail.link" v-clipboard:success="successClick"
                        ><i class="iconfont icon-url mr-2"></i>复制链接</a
                      >
                      <!-- <a class="btn btn-primary rounded-lg" data-toggle="dropdown">分享</a>
                      <div class="dropdown-menu text-sm">
                        <a class="dropdown-item copy-location-href"><i class="iconfont icon-url mr-2"></i>复制链接</a>
                      </div> -->
                    </div>
                  </div>
                  <div class="mb-4 category-swiper" v-if="deviceType !== 'phone'">
                    <div class="swiper-wrapper">
                      <div class="card-grid">
                        <div class="url-card" v-for="(items, child) in getDataList" :key="child">
                          <div class="url-body" @click="goDetails(items)">
                            <a class="url-content">
                              <div class="url-img">
                                <img class="lazy" :src="APIUM + items.image" :alt="items.title" />
                              </div>
                              <div class="url-info">
                                <div class="overflowClip_1 mb-1 mb-md-2">
                                  <strong>{{ items.title }}</strong
                                  ><span class="badge" v-if="items.tagName">{{ items.tagName }}</span>
                                </div>
                                <p class="overflowClip_2">
                                  {{ items.content }}
                                </p>
                              </div>
                            </a>
                            <div class="url-link" v-if="items.tag">
                              <div class="swiper-wrapper">
                                <a> {{ items.tag }}</a>
                              </div>
                            </div>
                            <div v-if="items.isShowMoreTag" class="url-details" rel="nofollow">
                              <i class="iconfont icon-goto"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                     
                    </div>
                  </div>
				  
				  <div class="gengduo" v-else>
				  	<div class="list" >
						<div class="item" v-for="(items, child) in getDataList"  @click="goDetails(items)">
							<div class="left">
								<img class="lazy" :src="APIUM + items.image" :alt="items.title" />
								<p style="margin-left: 10px;"><strong>{{ items.title }}</strong></p>
							</div>
							<div class="right">
								
							</div>
						</div>
					</div>
				  </div>
				  
				  
                  <div class="posts-nav mb-3 mb-md-4">
                    <el-pagination
                      background
                      layout="prev, pager, next"
                      :total="total"
                      @prev-click="prevClick"
                      @next-click="nextClick"
                      @current-change="handleCurrentChange"
                    >
                    </el-pagination>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹框 -->
    <asideDrawer />
  </div>
</template>

<script>
import asideBar from '@/views/layout/components/aside'
import asideDrawer from '@/views/layout/components/asideDrawer'
import { getNavIndex, getCat } from '@/api/http'
import { API_URL } from '@/utils/request.js'
export default {
  components: { asideBar, asideDrawer },
  data() {
    return {
      actionStyle: 'Popular', // Popular 热门   new 最新
      detail: {
        name: '',
        desc: '',
        link: ''
      },

      total: 0,
      getDataList: [],
      APIUM: API_URL,
      page: 1,
      id: '',
	  deviceType:''
    }
  },

  created() {
    this.id = this.$route.query.id
    this.getZiXun()
    this.getCat()
  },

  methods: {
    async getCat() {
      var that = this
      const data = await getCat()
      var res = data.data.data
      res.forEach((item) => {
        if (that.id == item.id) {
          this.detail.name = item.name
          this.detail.desc = item.description
        }
      })
    },
    async getZiXun() {
      const res = await getNavIndex({ category_id: this.$route.query.id })
      this.getDataList = res.data.data.rows
      this.total = res.data.data.total
    },
    goDetails(val) {
		console.log();
		window.open('https://' + val.link, '_blank')
      // this.$router.push('/details?id=' + val.id+'&cid='+val.category_id)
    },
    clickJump(val) {
      this.$router.push(val)
    },
    prevClick(val) {
      this.page = val
      this.getZiXun()
    },
    nextClick(val) {
      this.page = val
      this.getZiXun()
    },
    handleCurrentChange(val) {
      this.page = val
      this.getZiXun()
    },
    // 复制成功
    successClick() {
      this.$message({
        message: '复制成功',
        type: 'success'
      })
    }
  }
}
</script>

<style>
	
.gengduo .list  img{
	width: 30px;
	height: 30px;
}
.item .left{
	display: flex;
	align-items: center;
}
.item .left p{
	width: 120px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.list{
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 20px 0;
}
.item{
	width: 48%;
	background: #F1F4F9;
	margin-top: 10px;
	border-radius: 10px;
	padding: 10px;
	justify-content: space-between;
}
	
.tab-more {
  & > .el-tabs__header {
    border: none;
  }
  .el-tabs__nav {
    border: none !important;
  }
  .el-tabs__nav-scroll {
    padding: 20px 0;
  }
  .el-tabs__item {
    color: #333;
    background-color: rgba(150, 150, 150, 0.16);
    border-radius: 3px;
    border: none !important;
    margin-right: 15px;
    position: relative;
  }
  .el-tabs__item.is-active {
    color: #fff;
    background-color: #2254f4;
    box-shadow: 0 0 8px rgba(34, 84, 244, 0.6);
    &::before {
      content: '';
      position: absolute;
      bottom: -17px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 0;
      border: 10px solid transparent;
      border-top-color: #2254f4;
    }
  }
}
</style>
